// @file Define functions to make API calls
import getCsrfToken from '@@/bits/csrf_token'
import window, { browsingContextUid } from '@@/bits/global'
import { sleep } from '@@/bits/sleep'
import { getPathWithLeadingSlash } from '@@/bits/url'
import { apiFetch } from '@padlet/arvo'
import type { FetchOptions } from '@padlet/fetch'
import { FetchError } from '@padlet/fetch'

export const getApiFetchCommonOptions = (): FetchOptions => {
  return {
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'X-UID': window?.ww?.uid || browsingContextUid,
    },
  }
}

const apiFetchJson = async (url: string, options: FetchOptions = {}): Promise<any> => {
  const commonOptions: FetchOptions = {
    headers: {
      Accept: 'application/json, application/vnd.api+json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-Token': getCsrfToken(),
      'X-UID': window?.ww?.uid || browsingContextUid,
    },
  }

  // Merge `commonOptions.headers` with `options.headers` so callers don't have to provide
  // the common headers when they add custom headers.
  if (typeof options.headers === 'object') {
    options.headers = { ...commonOptions.headers, ...options.headers }
  }

  const pathWithLeadingSlash = getPathWithLeadingSlash(url)
  return await apiFetch<any>(pathWithLeadingSlash, commonOptions, options)
}

const fetchJsonWithRetries = async (url: string, options: FetchOptions = {}, maxTries = 4): Promise<any> => {
  try {
    return await apiFetchJson(url, options)
  } catch (error) {
    if (maxTries === 1 || error.status < 500) throw error
    await sleep(10, 50)
    return await fetchJsonWithRetries(url, options, maxTries - 1)
  }
}

export { FetchError, apiFetchJson as fetchJson, fetchJsonWithRetries }
